export const apiNamespace = process.env.REACT_APP_BACKEND_URL || '//local.alliot.cloud/api';

export const localStorageSchemaVersion = '201904241100';

export const mapboxApiKey =
  'pk.eyJ1IjoiYWxsaW90ZWsiLCJhIjoiY2tvYmloemdsMDB4eDJ1czk5MWt3M2VqNiJ9.kCCXHtnTFl7xA_Tkfd9o8g';

export const CHART_REFRESH_INTERVAL = 10000;

export const redirectUrl = process.env.REACT_APP_REDIRECT_TO_NEW_ALLIOT_URL;
