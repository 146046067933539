import { Field, Form } from '@core/forms';
import Validates, { format, passwordsMatching, presence } from '@core/forms/validations';
import { ProcessingButton, Spinner } from '@shared';
import { IdentifierWidgetComponent, SinglePictureComponent } from '@shared/forms';
import PartnerSelector from '@shared/forms/PartnerSelector';
import React from 'react';
import { Alert, CardTitle, Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'firstName' }),
  presence({ of: 'lastName' }),
  presence({ of: 'email' }),
  presence({ of: 'identifierValue' }),
  // presence({ of: 'identifierType' }),
  presence({ of: 'rootRole' }),
  format({
    of: 'phone',
    with: /^\+\d{5,}$/gi,
    message: 'Debe ser de la forma "+ Area Code" ...',
  }),
  passwordsMatching('password', 'passwordConfirmation'),
);

const PictureComponent = SinglePictureComponent((token) => `/avatars/${token}`, '/avatars');

const UserFormPresentation = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  showPasswordFields,
  title,
  loading,
  error,
}) => {
  return (
    <div>
      <CardTitle className="title-padded text-center" tag={'h4'}>
        {title || 'Crear usuario'}
      </CardTitle>

      {!showPasswordFields && (
        <Alert color="info">Se enviará una contraseña al usuario al correo indicado en el formulario.</Alert>
      )}

      <Form error={error} noValidate onSubmit={handleSubmit}>
        <h5 className="mb-3">Identificación</h5>
        <Row>
          <Col>
            <Field label="Nombres" name="firstName" type="text" required />
          </Col>
          <Col>
            <Field label="Apellidos" name="lastName" type="text" required />
          </Col>
        </Row>
        <Row>
          {/* <Col>
            <Field
              label="Tipo de Identificación"
              type="select"
              name="identifierType"
              required
              options={{
                rut: 'RUT Chileno',
                generic: 'otro',
              }}
            />
          </Col> */}
          <Col>
            <Field
              label="Identificación"
              component={IdentifierWidgetComponent}
              name="identifierValue"
              type="text"
              required
              help="Sin puntos ni guiones."
            />
          </Col>
          <Col />
          {/* <Col /> */}
        </Row>

        <h5 className="mb-3">Información de contacto</h5>

        <Row>
          <Col>
            <Field label="Email" name="email" type="text" required />
          </Col>
          <Col>
            <Field label="Teléfono" name="phone" type="text" required />
          </Col>
        </Row>

        <h5 className="mb-3">Información Empresarial</h5>

        <Row>
          <Col>
            <Field label="Empresa" name="company" type="text" />
          </Col>
          <Col>
            <Field label="Área" name="area" type="text" />
          </Col>
          <Col>
            <Field label="Turno" name="workshift" type="text" />
          </Col>
          <Col>
            <Row>
              <Col>
                <Field
                  label="Socio de negocios"
                  name="partnerId"
                  component={PartnerSelector}
                  filters={{ active: true }}
                  help="Socio de negocios al que pertenecerá el usuario"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <h5 className="mb-3">Información de acceso</h5>

        <Row>
          <Col>
            <Field
              label="Rol global"
              type="select"
              name="rootRole"
              required
              options={{
                '': '',
                user: 'Usuario',
                support: 'Soporte',
                root: 'Root',
              }}
            />
          </Col>
          <Col>
            <Field
              label="¿Nuevo Alliot Cloud?"
              type="select"
              name="migrated"
              required
              options={{
                true: 'Sí',
                false: 'No',
              }}
            />
          </Col>
        </Row>

        {showPasswordFields && (
          <Row>
            <Col>
              <Field label="Contraseña" name="password" type="password" />
            </Col>
            <Col>
              <Field label="Confirmar contraseña" name="passwordConfirmation" type="password" />
            </Col>
          </Row>
        )}

        <Field
          name="avatarToken"
          component={PictureComponent}
          label="Imagen de perfil"
          help="Debe ser una imagen jpg, jpeg, gif ó png. Se recomienda una imagen aproximadamente cuadrada de 800 x 800 píxeles. El servidor procesa la imagen para generar múltiples versiones con tamaños adecuados."
        />

        <div className="text-center">
          <ProcessingButton
            type="submit"
            role="button"
            color="primary"
            outline
            disabled={pristine || invalid}
            processing={submitting}
            width="10em"
          >
            {loading ? <Spinner /> : 'Guardar'}
          </ProcessingButton>
        </div>
      </Form>
    </div>
  );
};

export const UserForm = reduxForm({
  form: 'userForm',
  validate,
})(UserFormPresentation);
