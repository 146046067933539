import React from 'react';
import { Route, Redirect } from 'react-router';

import { connect, notificationsGenerators } from '@store';
import { currentMembership } from '@store/session';
import { redirectUrl } from '@config';

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = (dispatch) => ({
  notifyPermissions: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No autorizado',
        text:
          'No cuentas con los permisos suficientes para acceder a esta sección. En caso de tratarse de un error, por favor contacta a un administrador.',
        color: 'danger',
        duration: 10000,
      }),
    ),
});

function present(variable) {
  return variable && Array.isArray(variable) && variable.length > 0;
}

const FailedRedirect = ({ to, location }) => (
  <Redirect
    to={{
      pathname: to,
      state: { from: location },
    }}
  />
);

const ProtectedRoute = ({
  acceptedRootRoles,
  excludedRootRoles,
  acceptedMemberships,
  excludedMemberships,
  session,
  location,
  component: Component,
  componentProps,
  notifyPermissions,
}) => {
  if (!session.loggedIn) {
    return <FailedRedirect location={location} to={`/account/login`} />;
  }

  if (session.loggedIn && session?.user?.migrated) {
    console.log('REDIRECT', redirectUrl);
    window.location.href = redirectUrl;
    return;
  }

  const rootRole = session && session.user && session.user.rootRole;
  const userMembership = session && session.user && session.user.memberships.length > 0 && currentMembership(session);

  const acceptanceRules = [
    present(acceptedRootRoles) && acceptedRootRoles.includes(rootRole),
    present(acceptedMemberships) && userMembership && acceptedMemberships.includes(userMembership.role),
  ];

  const exclusionRules = [
    present(excludedRootRoles) && excludedRootRoles.includes(rootRole),
    present(excludedMemberships) && userMembership && excludedMemberships.includes(userMembership.role),
  ];
  // User doesn't have permission
  if (
    [acceptedRootRoles, acceptedMemberships, excludedRootRoles, excludedMemberships].reduce(
      (c, v) => c || present(v),
      false,
    ) &&
    (exclusionRules.find((v) => v) || !acceptanceRules.find((v) => v))
  ) {
    if (!session.mustSetMembership) {
      notifyPermissions && notifyPermissions();
    }
    return <FailedRedirect location={location} to="/" />;
  }

  return <Component {...componentProps} />;
};

const ProtectedRoutePresentation = ({
  session, // from redux
  acceptedRootRoles,
  excludedRootRoles,
  acceptedMemberships,
  excludedMemberships,
  component,
  notifyPermissions,
  ...routeProps
}) => (
  <Route
    render={(props) => (
      <ProtectedRoute
        acceptedRootRoles={acceptedRootRoles}
        excludedRootRoles={excludedRootRoles}
        acceptedMemberships={acceptedMemberships}
        excludedMemberships={excludedMemberships}
        session={session}
        location={props.location}
        component={component}
        componentProps={props}
        notifyPermissions={notifyPermissions}
      />
    )}
    {...routeProps}
  />
);

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutePresentation);
